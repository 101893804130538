import React from "react";
import "./content-title.scss";

interface IContentTitle {
  title: string;
}

export const ContentTitle: React.FC<IContentTitle> = ({ title }) => {
  return <h2 className="content-title">{title}</h2>;
};
