import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import React from "react";

export const NavigationItems = () => {
  const location = useLocation();

  return (
    <div className="navigation-items">
      <Link
        to="/"
        className={classNames({ active: location.pathname === "/" })}
      >
        Home
      </Link>
      <Link
        to="/about"
        className={classNames({
          active: location.pathname === "/about",
        })}
      >
        Über uns
      </Link>
      <Link
        to="/members"
        className={classNames({
          active: location.pathname === "/members",
        })}
      >
        Mitglieder
      </Link>
      <Link
        to="/contact"
        className={classNames({
          active: location.pathname === "/contact",
        })}
      >
        Kontakt
      </Link>
    </div>
  );
};
