import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AboutUs, Contact, Home, Members } from "./pages";
import "./app.scss";

export const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/about" component={AboutUs} />
          <Route exact={true} path="/members" component={Members} />
          <Route exact={true} path="/contact" component={Contact} />
        </Switch>
      </Router>
    </div>
  );
};
