import React from "react";
import { EventsList, PageLayout } from "../components";

const allEvents = [
  {
    title: "Flämmli-Treff",
    description:
      "Durchschnittlich drei mal pro Jahr treffen wir uns im Next in Brunnen für einen gemeinsamen geselligen Abend.",
  },
  {
    title: "Generalversammlung",
    description:
      "Die alljährliche GV findet im Next Brunnen nach Abschluss des Vereinsjahres im Juni statt.",
  },
  {
    title: "Arbeitseinsätze",
    description:
      "In einzelnen Einsätzen bessern wir unsere Vereinskasse auf und unterstützen das Vereinsleben.",
  },
  {
    title: "Externe Anlässe",
    description:
      "Wir sind auch an speziellen Anlässen anzutreffen, so haben wir uns bereits an einen Match des EHC Seewen, oder des FC Brunnen als lautstarke Unterstützung begeben. Auch gehen wir nächstes Jahr gemeinsam die Zgraggen Brennerei in Lauerz besichtigen.",
  },
];

export const AboutUs = () => {
  return (
    <PageLayout title="Über uns">
      <p>
        Wir sind ein junger Verein aus Brunnen gegründet im Sommer 2018, der
        sich gerne mal dem Geniessen eines Flämmlis widmet.
      </p>

      <h2>Anlässe</h2>
      <p>Diverse Anlässe prägen unser Vereinsleben:</p>
      <EventsList events={allEvents} />

      <h2>Flämmli-Trophy</h2>
      <p>
        In unserer alljährlichen Flämmli-Trophy zeichnen wir an der GV die
        Sieger aus, die das Jahr hindurch am Meisten "Flämmlis" in der Next Bar
        konsumiert haben.
      </p>
      <h3>2020</h3>
      <ol>
        <li>Flavio Casagrande</li>
        <li>Michael Bissig</li>
        <li>Christian Bürgi</li>
      </ol>
    </PageLayout>
  );
};
