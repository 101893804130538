import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdMenu, MdClose } from "react-icons/md";
import Logo from "../../images/logo-horizontal.png";
import { NavigationItems } from "./navigation-items";

interface IHeaderProps {
  hasLogo?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ hasLogo = true }) => {
  const history = useHistory();

  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState<boolean>(
    false
  );

  return (
    <header className="header">
      <div className={classNames("container", "container--header")}>
        {hasLogo ? (
          <img
            src={Logo}
            alt="Flämmli Club Brunnen"
            className="header__image"
            onClick={() => history.push("/")}
          />
        ) : (
          <span />
        )}
        <div
          className="header__navigation--mobile-toggle"
          onClick={() => setIsMobileNavigationOpen(true)}
        >
          <MdMenu />
        </div>
        {isMobileNavigationOpen && (
          <div className="header__navigation--mobile-popup">
            <div
              className="header__navigation__close"
              onClick={() => setIsMobileNavigationOpen(false)}
            >
              <MdClose />
            </div>
            <NavigationItems />
          </div>
        )}
        <div className="header__navigation--desktop">
          <NavigationItems />
        </div>
      </div>
    </header>
  );
};
