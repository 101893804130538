import React from "react";

export interface IEventItemProps {
  title: string;
  description: string;
}

export const EventItem: React.FC<IEventItemProps> = ({
  title,
  description,
}) => {
  return (
    <div className="event-item">
      <div className="event-item__title">{title}</div>
      <div className="event-item__description">{description}</div>
    </div>
  );
};
