import React, { useState } from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

interface IAccordionProps {
  title: string;
  children: React.ReactNode;
}

export const Accordion: React.FC<IAccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion">
      <div className="accordion__title" onClick={() => setIsOpen(!isOpen)}>
        <h3 className="accordion__header">{title}</h3>
        <div className="accordion__toggle-icon">
          {isOpen ? <FaMinusCircle /> : <FaPlusCircle />}
        </div>
      </div>
      {isOpen && <div className="accordion__content">{children}</div>}
    </div>
  );
};
