import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { ContentTitle } from "./content-title";

interface ILayoutProps {
  children: React.ReactNode;
  title?: string;
}

export const PageLayout: React.FC<ILayoutProps> = ({ children, title }) => {
  return (
    <>
      <Header />
      <div className="container">
        {title && <ContentTitle title={title} />}
        {children}
      </div>
      <Footer />
    </>
  );
};
