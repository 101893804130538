import UnknownPhoto from "../../images/members/unknown-person.png";
import React from "react";
import { IMember } from "../../utils/members-data";

import "./member.scss";

interface IMemberProps {
  data: IMember;
}

export const Member: React.FC<IMemberProps> = ({ data }) => {
  return (
    <div className="member">
      <img
        className="member__photo"
        src={UnknownPhoto}
        alt={`Mitglied ${data.lastName}`}
      />
      <div className="member__info">
        <div className="member__name">{`${data.firstName} ${data.lastName}`}</div>
        {data.task && <div className="member__task">{data.task}</div>}
        <div className="member__since">Seit: {data.since}</div>
      </div>
      {data.extraordinary && (
        <div className="member__extraordinary">{data.extraordinary}</div>
      )}
    </div>
  );
};
