import { DateTime } from "luxon";
import React, { useMemo } from "react";

const countByYear = [
  { date: "2020-06-13", count: 710 },
  { date: "2021-06-12", count: 586 },
];

export const RoundedCounter = () => {
  const latestDate = useMemo(
    () =>
      countByYear
        .map((item) => item.date)
        .sort((a, b) => b.localeCompare(a))[0],
    []
  );

  return (
    <div className="rounded-counter">
      <div className="rounded-counter__number">
        {countByYear
          .map((item) => item.count)
          .reduce((prev, next) => prev + next, 0)}
      </div>
      <div className="rounded-counter__date">
        per {DateTime.fromISO(latestDate).toFormat("dd.MM.yyyy")}
      </div>
    </div>
  );
};
