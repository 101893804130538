import classNames from "classnames";
import React from "react";
import { PageLayout, Member } from "../components";
import { allMembers, IMember } from "../utils/members-data";

import "./members.scss";

export const Members = () => {
  return (
    <PageLayout title="Unsere Mitglieder">
      <div className={classNames("members__container", "content")}>
        <div className="members__listing">
          {allMembers
            .sort((a: IMember, b: IMember) => {
              if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
                return -1;
              }
              if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
                return 1;
              }

              return 0;
            })
            .map((member: IMember) => (
              <Member data={member} />
            ))}
        </div>
      </div>
    </PageLayout>
  );
};
