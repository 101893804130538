import React from "react";

export interface IMember {
  firstName: string;
  lastName: string;
  since: string;
  photo?: React.ReactNode;
  task?: string;
  extraordinary?: string;
}

export const allMembers: IMember[] = [
  {
    firstName: "Pascal",
    lastName: "Zehnder",
    photo: undefined,
    since: "Gründung",
    task: "Vizepräsident / Verantwortlich Information",
  },
  {
    firstName: "Pascal",
    lastName: "Annen",
    photo: undefined,
    since: "Gründung",
    task: "Präsident",
    extraordinary:
      "Der einzig wahre Gründungspräsident, der das Tempo der Flämmli-Kadenz angeben darf.",
  },
  {
    firstName: "Marco",
    lastName: "Betschart",
    photo: undefined,
    since: "Gründung",
    task: "Kassier",
    extraordinary:
      "Hat unsere Finanzen stets im Griff und geniesst dabei meist ein Flämmli.",
  },
  {
    firstName: "Sandro",
    lastName: "Schönbächler",
    since: "2018",
    task: "Aktuar",
    extraordinary:
      "Das Vorstandsmitglied mit den meisten Absenzen bei Vorstandssitzungen, der allerdings trotzdem die spannendsten Protokolle schreibt.",
  },
  {
    firstName: "Kevin",
    lastName: "Achermann",
    since: "2018",
    extraordinary: "Ein Flämmli oder Bier schadet nie.",
  },
  {
    firstName: "Thomas",
    lastName: "Betschart",
    since: "2018",
    extraordinary:
      "Einer der drei Gründer einer hauseigenen Flämmlli-Club-WGs.",
  },
  {
    firstName: "Michael",
    lastName: "Bissig",
    since: "2018",
    task: "Revisor",
    extraordinary:
      "Bei ihm zuhause steht bereits ein Williams Birnen Baum von welchem wir in Zukunft dann hoffentlich mal unseren eigenen Williams brennen können.",
  },
  {
    firstName: "Christian",
    lastName: "Bürgi",
    since: "2018",
    extraordinary:
      "Unser Mann aus Lauerz der sich mit seinen spendablen Gesten gerne beispielsweise als Götti von einem von unseren zwei Birnenbäumen betätigt.",
  },
  {
    firstName: "Flavio",
    lastName: "Casagrande",
    since: "2018",
    extraordinary:
      "Erstmaliger Gewinner der Flämmli-Trophy im Jahr 2020 und gern gesehener Gast im Next in Brunnen.",
  },
  {
    firstName: "Nico",
    lastName: "Ehrler",
    task: "Revisor",
    since: "2018",
    extraordinary:
      "Legt während Diskussionen gerne seinen Zeigefinger auf den Tisch und geniesst dabei sein geliebtes Flämmli.",
  },
  {
    firstName: "Loris",
    lastName: "Guarino",
    since: "2018",
    extraordinary: "Das Nesthäckchen unseres Vereins.",
  },
  {
    firstName: "Pascal",
    lastName: "Gwerder",
    since: "Gründung",
    extraordinary:
      "Gweide war bei der Gründung und Ideen des Vereins massgeblich beteiligt.",
  },
  {
    firstName: "Renato",
    lastName: "Haas",
    since: "2018",
    extraordinary: "Auch dabei!",
  },
  {
    firstName: "Lukas",
    lastName: "Kamer",
    since: "2018",
    extraordinary:
      "Zeigt nach einigen Flämmlis jeweils ein wahrliches Pfeiffkonzert.",
  },
  {
    firstName: "Yannick",
    lastName: "Küttel",
    since: "2018",
    extraordinary: "Er hilft gerne wieder mal in unserer Stammbeiz aus.",
  },
  {
    firstName: "Langenegger",
    lastName: "Stefan",
    since: "2018",
    extraordinary:
      "Für ihn wurde bereits ein Stück geschrieben, welches bei Flämmli-Treffen stets zum Besten gegeben wird.",
  },
  {
    firstName: "Maurer",
    lastName: "Philipp",
    since: "2018",
    extraordinary: "Der Mann aus Holz. Lieblingsbaum: Williams-Birnenbaum.",
  },
  {
    firstName: "Styger",
    lastName: "Patrick",
    since: "2018",
    extraordinary:
      "Kommt gerne vom Steinerberg wieder mal nach Brunnen um ein Flämmli zu geniessen.",
  },
  {
    firstName: "Tom",
    lastName: "Vogel",
    since: "2020",
    extraordinary:
      "Hat es während dem extraordinären Corona-Jahr geschafft als Mitglied aufgenommen zu werden.",
  },
];
