import { FaCalendarDay, FaUserFriends, FaLocationArrow } from "react-icons/fa";
import React from "react";

export const Facts = () => {
  return (
    <div className="facts">
      <div className="facts-item">
        <FaCalendarDay className="facts-item__icon" />
        <div className="facts-item__text">Gegründet im Sommer 2018.</div>
      </div>
      <div className="facts-item">
        <FaUserFriends className="facts-item__icon" />
        <div className="facts-item__text">Aktuell 19 Mitglieder.</div>
      </div>
      <div className="facts-item">
        <FaLocationArrow className="facts-item__icon" />
        <div className="facts-item__text">6440 Brunnen.</div>
      </div>
    </div>
  );
};
