import React from "react";
import { PageLayout } from "../components";
import { Accordion } from "../components/accordion";

export const Contact = () => {
  return (
    <PageLayout title="Kontakt">
      <p>
        Meist sind wir in unserem Stammlokal, dem Next in Brunnen anzutreffen,
        allerdings könnt ihr uns auch ausserhalb der ordentlichen Flämmli-Zeiten
        erreichen.
      </p>

      <h3>Mitglied werden</h3>
      <p>
        Um in unseren Verein aufgenommen zu werden, braucht es nach Statuten
        einen einstimmigen GV Entscheid. Damit du dich allerdings bei Interesse
        bei uns melden kannst, darfst du dich gerne über das folgende Formular
        bewerben:
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScdObgTPI7i6PeE9ENNgLnkYu8hbfMd3_A7KDXeGLqE6VKbNQ/viewform?usp=sf_link">
          Anmeldungsformular
        </a>
      </p>

      <Accordion title="Impressum">
        <h3>Copyright</h3>
        <p>
          Das Copyright für sämtliche Inhalte dieser Website liegt bei
          Flämmli-Club Brunnen.
        </p>

        <h3>Disclaimer</h3>
        <p>
          Alle Texte und Links wurden sorgfältig geprüft und werden laufend
          aktualisiert. Wir sind bemüht, richtige und vollständige Informationen
          auf dieser Website bereitzustellen, übernehmen aber keinerlei
          Verantwortung, Garantien oder Haftung dafür, dass die durch diese
          Website bereitgestellten Informationen, richtig, vollständig oder
          aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne
          Vorankündigung die Informationen auf dieser Website zu ändern und
          verpflichten uns auch nicht, die enthaltenen Informationen zu
          aktualisieren. Alle Links zu externen Anbietern wurden zum Zeitpunkt
          ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir
          nicht für Inhalte und Verfügbarkeit von Websites, die mittels
          Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte
          verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite,
          auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden
          direkter, indirekter oder finanzieller Natur ist oder ein sonstiger
          Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder
          anderen Gründen aller Art ergeben könnte.
        </p>
      </Accordion>
    </PageLayout>
  );
};
