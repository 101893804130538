import React from "react";
import dayjs from "dayjs";
import { FaFacebookSquare, FaMailBulk } from "react-icons/fa";
import classNames from "classnames";

export const Footer = () => {
  const currentYear = dayjs().year();

  return (
    <footer className="footer">
      <div className={classNames("container", "footer__container")}>
        <div>© Flämmlil-Club Brunnen {currentYear}</div>
        <div className="footer__links">
          <a
            href="https://www.facebook.com/Fl%C3%A4mmli-Club-Brunnen-504348376689660"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare />
          </a>
          <a href="mailto:pascal.annen@bluewin.ch">
            <FaMailBulk />
          </a>
        </div>
      </div>
    </footer>
  );
};
