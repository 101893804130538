import React from "react";
import { EventItem, IEventItemProps } from "../event-item";

interface IEventListProps {
  events: IEventItemProps[];
}

export const EventsList: React.FC<IEventListProps> = ({ events }) => {
  return (
    <div className="events-list">
      {events.map((event) => (
        <EventItem title={event.title} description={event.description} />
      ))}
    </div>
  );
};
