import React from "react";
import classNames from "classnames";

interface IHomePageSection {
  children: React.ReactNode;
  isSecondary?: boolean;
  isTopSection?: boolean;
}

export const HomePageSection: React.FC<IHomePageSection> = ({
  children,
  isSecondary,
  isTopSection,
}) => {
  return (
    <div
      className={classNames("home-page__section", {
        "home-page__section--top": isTopSection,
        "home-page__section--white": isSecondary,
      })}
    >
      {children}
    </div>
  );
};
