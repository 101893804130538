import React from "react";
import EhcSeewenPhoto from "../images/ehc-seewen-match.jpeg";
import { Header, Footer, HomePageSection, Facts } from "../components";
import "./home.scss";
import { NextBarMap } from "../components/next-bar-map";
import { RoundedCounter } from "../components/rounded-counter";

export const Home = () => {
  return (
    <>
      <Header hasLogo={false} />
      <div className="home-page">
        <HomePageSection isTopSection={true}>
          <h1>Flämmli-Club Brunnen</h1>
        </HomePageSection>

        <HomePageSection isSecondary={true}>
          <h2>Wer sind wir?</h2>
          <Facts />

          <img
            className="home-page__main-photo"
            src={EhcSeewenPhoto}
            alt="Nach dem EHC Seewen Match-Besuch"
          />
        </HomePageSection>

        <HomePageSection>
          <h2>Unser zuhause</h2>
          Ihr trefft uns im Next in Brunnen.
          <NextBarMap />
        </HomePageSection>

        <HomePageSection isSecondary={true}>
          <h2>Flämmli-Counter</h2>
          <RoundedCounter />
        </HomePageSection>

        <HomePageSection>
          <h2>Zubereitung</h2>
          <p>
            2 Würfelzucker in den Espresso geben und austrinken, ohne den Zucker
            zu verrühren! Williams in die Espressotasse geben. Das Ganze im Glas
            anzünden und den Zucker umrühren und in der Flamme ein bisschen
            karamelisieren lassen. Wenn der Zucker weg ist, Flamme löschen, in
            ein Schnapsglas leeren und auskühlen lassen. Danach ist das Flämmli
            bereit für den Genuss.
          </p>
        </HomePageSection>
      </div>
      <Footer />
    </>
  );
};
