import React from "react";
import GoogleMap from "google-map-react";

import "./next-bar-map.scss";

const renderMarkers = (map: any, maps: any) => {
  new maps.Marker({
    position: { lng: 8.605829738055526, lat: 46.99443924105108 },
    map,
    title: "Next Bar & Lounge Brunnen",
  });
};

export const NextBarMap = () => {
  return (
    <div className="next-bar-map">
      <GoogleMap
        bootstrapURLKeys={{ key: "AIzaSyCwTXMoFMr-mQ56vhZEWV2OdmQbuBXHulw" }}
        center={{ lng: 8.6058, lat: 46.9944 }}
        zoom={15}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};
